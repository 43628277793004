import React, { useEffect } from "react";

function CoreTeam() {
  useEffect(() => {
    document.title = "Core Team";
  });
  return (
    <div className="content">
      <div className="w-container">
        <div className="page-head">
          <div className="page-title">
            <h1 className="h1">Core-Team</h1>
          </div>
          <div className="flex-vert">
            <div class="sub-hrule"></div>
            <p className="paragraph-center sub">
              The Core-Team is the adult team at Wiess and has been an integral
              part of keeping Wiess traditions and culture alive throughout the
              years. They are there for you, no matter what you need; you can go
              to them for advice or just for a listening ear.
            </p>
          </div>
        </div>
        <div id="ateamnav" className="from-navbar-ref"></div>
        <div id="ateam" className="page-nav-ref"></div>
        <div className="sub-head">
          <h3 className="h3">Magisters&nbsp;</h3>
          <div className="sub-hrule"></div>
        </div>
        <div className="person-card">
          <div className="prof-pic">
            <img
              src="/images/ateam/flavio-fabiana.jpeg"
              height="200"
              alt="Magisters"
            />
          </div>
          <div className="name">
            <h4 className="h4">Flavio Cunha & Fabiana Santos</h4>
          </div>
        </div>
        <div className="sub-head">
          <h3 className="h3" style={{ paddingTop: "70px" }}>
            College Coordinator&nbsp;
          </h3>
          <div className="sub-hrule"></div>
        </div>
        <div className="person-card">
          <div className="prof-pic">
            <img
              src="/images/ateam/jenny.jpg"
              height="200"
              alt="College Coordinator"
            />
          </div>
          <div className="name">
            <h4 className="h4">Jenny Toups</h4>
          </div>
        </div>

        <div className="sub-head">
          <h3 className="h3" style={{ paddingTop: "70px" }}>
            Resident Associates (RA)&nbsp;
          </h3>
          <div className="sub-hrule"></div>
        </div>
        <div class="people-section">
          <div class="resa-grid">
            <div className="person-card">
              <div className="prof-pic">
                <img
                  src="/images/ateam/carissa-nick.jpg"
                  height="200"
                  alt="3rd Floor RAs"
                  sizes="100vw"
                  srcSet="/images/ateam/carissa-nick-p-500.jpeg 500w, /images/ateam/carissa-nick-p-800.jpeg 800w, /images/ateam/carissa-nick-p-1080.jpeg 1080w"
                />
              </div>
              <div className="name">
                <h4 className="h4">Carissa Zimmerman & Nick Espinosa</h4>
              </div>
            </div>
            <div className="person-card">
              <div className="prof-pic">
                <img
                  src="/images/ateam/lach-kari-mullen.jpg"
                  height="200"
                  alt="4th Floor RAs"
                  sizes="100vw"
                  srcSet="/images/ateam/lach-kari-mullen.jpg 500w, /images/ateam/lach-kari-mullen.jpg 800w, /images/ateam/lach-kari-mullen.jpg 1080w"
                />
              </div>
              <div className="name">
                <h4 className="h4">Lach & Kari Mullen</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreTeam;
