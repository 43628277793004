import React from "react";
import { useEffect } from "react";

function Health() {
  useEffect(() => {
    document.title = "Health and Wellbeing";
  });

  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">Health &amp; Wellbeing</h1>
          </div>
        <div class="sub-hrule"></div>
        </div>
        <div class="people-section">
          <div id="rhanav" class="from-navbar-ref"></div>
          <div id="rha" class="page-nav-ref"></div>
          <div class="sub-head">
            <h3 class="h3">Rice Health Advisors (RHA)</h3>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Rice Health Advisors (RHAs) are students who are trained to give
              mental and psychological support to peers, along with aid for
              minor physical problems. RHAs are available as resources to talk
              to you about any wellness concerns and provide kits that contain
              minor first aid tools and medication.
            </p>
          </div>
          <div class="rha-content">
            <div class="rha-card-wrap">
              <h4 class="h4">Kayla Bierman (Head RHA)</h4>
              <a class="position" href="mailto: kab24@rice.edu">
                <u>kab24@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Tyler Kinzy (Head RHA)</h4>
              <a class="position" href="mailto: tdk2@rice.edu">
                <u>tdk2@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Amanda Le</h4>
              <a class="position" href="mailto: al106@rice.edu">
                <u>al106@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Amy Son</h4>
              <a class="position" href="mailto: as322@rice.edu">
                <u>as322@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Sam Mulford</h4>
              <a class="position" href="mailto: sem16@rice.edu">
                <u>sem16@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Leo Zhang</h4>
              <a class="position" href="mailto: lyz2@rice.edu">
                <u>lyz2@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Hazel Landers</h4>
              <a class="position" href="mailto: hl123@rice.edu">
                <u>hl123@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Evan Gebhart</h4>
              <a class="position" href="mailto: ecg7@rice.edu">
                <u>ecg7@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4"> Mason Howell</h4>
              <a class="position" href="mailto: mah24@rice.edu">
                <u>mah24@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Matthew Ochoa</h4>
              <a class="position" href="mailto: mo57@rice.edu">
                <u>mo57@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Isabelle Shen</h4>
              <a class="position" href="mailto: is51@rice.edu">
                <u>is51@rice.edu</u>
              </a>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div id="carenav" class="from-navbar-ref"></div>
          <div id="care" class="page-nav-ref"></div>
          <div class="sub-head">
            <h3 class="h3">Head Caregivers</h3>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Head Caregivers are students who promote safety and accountability
              at parties and similar events. They organize, promote, and present
              caregiving trainings in conjunction with EMS and Wellbeing.
            </p>
          </div>
          <div class="resa-grid">
           <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/health-wellbeing/HEADCAREMichaelWangHeadCaregiver.JPG"
                  height="200"
                  alt="Michael Wang"
                />
              </div>
              <div class="name">
                <h4 class="h4">Michael Wang</h4>
              </div>
              <div>
                <a class="position" href="mailto: mw87@rice.edu">
                  <u>mw87@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/health-wellbeing/HEADCAREHawaDialloHeadCaregiver.jpeg"
                  height="200"
                  alt="Hawa Diallo"
                />
              </div>
              <div class="name">
                <h4 class="h4">Hawa Diallo</h4>
              </div>
              <div>
                <a class="position" href="mailto: hd32@rice.edu">
                  <u>hd32@rice.edu</u>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Health;
