// This component is for the Navbar on Desktop

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Menu from "./Menu";
const Navbar = ({ toggleDrawer, routes }) => {
  return (
    <SNavbar>
      <NavContainer>
        <DrawerButton onClick={toggleDrawer}>
          <FaBars />
        </DrawerButton>
        <Link
            to="/"
            aria-current="page">
            <img
              src="/images/general/wiessshield.svg"
              height="64"
              alt="wiess shield"
            />
        </Link>
        <RightNav>
          <NavRoutes>
            {routes.map((route) => {
              if (route.subRoutes) {
                return <Menu route={route} key={route.name} />;
              }
              return (
                <NavRoute to={route.link} key={route.name}>
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
          <DonateButton href="https://riceconnect.rice.edu/donation/support-wiess-college">Donate</DonateButton>
        </RightNav>
      </NavContainer>
    </SNavbar>
  );
};

export default Navbar;

const DrawerButton = styled.button`
  all: unset;
  font-size: 3rem;
  display: grid;
  @media (min-width: 991px) {
    display: none;
  }
`;

const SNavbar = styled.nav`
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 150;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const NavContainer = styled.div`
  padding: 1rem;
  height: 80px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
`;

const RightNav = styled.div`
  display: flex;
  gap: 1rem;
`;
const NavRoutes = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
  display: flex;
  gap: 1rem;
  font-size: 20px;
  align-items: center;
`;

const NavRoute = styled(Link)`
  text-decoration: none;
  color: #000000;
  padding: 0.5rem;
  transition: 0.5s ease;
  
  &:hover {
    text-decoration: underline;
    text-decoration-color: #ffe38b;
    text-decoration-thickness: 5px;
    transition: 0.2s ease;
  }
`;

const DonateButton = styled.a`
  background-color: var(--dark-goldenrod);
  border: none;
  color: var(--dark-text-color);
  padding: 15px 20px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: goldenrod;
  }
`;