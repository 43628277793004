function Calendar() {
  return (
    <div
      data-w-id="1ebedd64-da31-9a2e-fc9b-1937496753b7"
      className="calendar-section"
    >
      <div className="w-container">
        <div className="page-head calendar">
          <h1 className="h1">Room Reservations</h1>
          <div className="hrule"></div>
          <a
            href="https://calendar.google.com/calendar/u/1?cid=Y19zazM2Y2E2bmNrdTNhdm9xNnJpanAyZ3RoNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t"
            className="popup-button blm-button"
            style={{ marginTop: "40px" }}
          >
            Add the Wiess Events Google Calendar
          </a>
        </div>
        <div className="flex-vert-center">
          <div className="calendar-tab w-embed w-iframe">
            <iframe
              src="https://calendar.google.com/calendar/embed?src=wiessrooms%40gmail.com&ctz=America%2FChicago"
              style={{ border: "solid 1px #777" }}
              width="700"
              height="525"
              title="calendar-tab"
            ></iframe>
          </div>
          <div className="calendar-desk w-embed w-iframe">
            <iframe
              src="https://calendar.google.com/calendar/embed?src=wiessrooms%40gmail.com&ctz=America%2FChicago"
              style={{ border: "solid 1px #777" }}
              width="800"
              height="600"
              title="calendar-desk"
            ></iframe>
          </div>
          <div className="calendar-mobile w-embed w-iframe">
            <iframe
              src="https://calendar.google.com/calendar/embed?src=wiessrooms%40gmail.com&ctz=America%2FChicago"
              style={{ border: "solid 1px #777" }}
              width="300"
              height="450"
              title="calendar-mobile"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Calendar;
