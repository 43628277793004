function AssociatesCalendar() {
    return (
      <div
        data-w-id="1ebedd64-da31-9a2e-fc9b-1937496753b7"
        className="calendar-section">
                      
          <div className="flex-vert-center">
                <div>
                    <a href="https://calendar.google.com/calendar/u/2?cid=ZjZhZGYwZGMxMGY4NDUwYjEwY2FiZjYwNTM4ZjhlMGRhZDE0Y2MwMTVjMGJkYzVkOGI0ZTJiZDlkNzUxMDI4Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t"
                    target= "_blank" rel="noopener noreferrer"
                    className="popup-button blm-button"
                    style={{ marginTop: "-50px", marginBottom: "50px"}}
                    >
                    Add the Associates Events Google Calendar
                    </a>  
                </div>





            <div className="calendar-tab w-embed w-iframe">
              <iframe
                src="https://calendar.google.com/calendar/embed?src=ZjZhZGYwZGMxMGY4NDUwYjEwY2FiZjYwNTM4ZjhlMGRhZDE0Y2MwMTVjMGJkYzVkOGI0ZTJiZDlkNzUxMDI4Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&ctz=America/Chicago"
                // style={{ border: "solid 1px #777" }}
                width="700"
                height="525"
                title="calendar-tab"
              ></iframe>
            </div>
            
             <div className="calendar-desk w-embed w-iframe">
              <iframe
                src="https://calendar.google.com/calendar/embed?src=ZjZhZGYwZGMxMGY4NDUwYjEwY2FiZjYwNTM4ZjhlMGRhZDE0Y2MwMTVjMGJkYzVkOGI0ZTJiZDlkNzUxMDI4Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&ctz=America/Chicago"
                // style={{ border: "solid 1px #777" }}
                width="800"
                height="600"
                title="calendar-desk"
              ></iframe>
             </div>
             
             <div className="calendar-mobile w-embed w-iframe">
              <iframe
                src="https://calendar.google.com/calendar/embed?src=ZjZhZGYwZGMxMGY4NDUwYjEwY2FiZjYwNTM4ZjhlMGRhZDE0Y2MwMTVjMGJkYzVkOGI0ZTJiZDlkNzUxMDI4Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&ctz=America/Chicago"
                // style={{ border: "solid 1px #777" }}
                width="300"
                height="450"
                title="calendar-mobile"
              ></iframe>
             </div>
             
          </div>

       </div>
        
    //   </div>
    );
  }
  export default AssociatesCalendar;
  