// This component is for the submenus on Desktop

import React from "react";
import { Link } from "react-router-dom";
import './Menu.css';

const Menu = ({ route }) => {
  return (
    <div className="menu">
      <div className="menu-button">{route.name}</div>
      <div className="sub-routes-container">
        {route.subRoutes.map((subRoute) => (
          <Link to={subRoute.link} className={`sub-route ${subRoute.name === "All Forms" ? "bold" : ""}`} key={subRoute.name}>
            {subRoute.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Menu;
