import { useEffect } from "react";

function Diversity() {
  useEffect(() => {
    document.title = "Diversity & Inclusion";
  });

  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">Diversity &amp; Inclusion</h1>
          </div>
        <div class="sub-hrule"></div>
        </div>
        <div class="people-section">
          <div id="dfnav" class="from-navbar-ref"></div>
          <div id="df" class="page-nav-ref"></div>
          <div class="sub-head">
            <h3 class="h3">Diversity Facilitators</h3>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Diversity Facilitators are students who are here to help you
              navigate our incredibly diverse campus. They strive to uphold an
              environment that allows us to facilitate healthy and open
              discussions on any issues related to diversity in the student
              body.
            </p>
          </div>
          <div class="resa-grid">
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/diversity-inclusion/DIVERSITYFACILITATORJackPinkert.jpg"
                  height="200"
                  alt="Jack Pinkert (DF)"
                />
              </div>
              <div class="name">
                <h4 class="h4">Jack Pinkert</h4>
              </div>
              <div>
                <a class="position" href="mailto: Jack.Pinkert@rice.edu">
                  <u>Jack.Pinkert@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/diversity-inclusion/DIVERSITYFACILITATORAlinaZhong.jpg"
                  height="200"
                  alt="Alina Zhong (DF)"
                />
              </div>
              <div class="name">
                <h4 class="h4">Alina Zhong</h4>
              </div>
              <div>
                <a class="position" href="mailto: az37@rice.edu">
                  <u>az37@rice.edu</u>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div id="dfnav" class="from-navbar-ref"></div>
          <div id="df" class="page-nav-ref"></div>
          <div class="sub-head">
            <h3 class="h3">Social Justice Library</h3>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Visit Upper Commons by the pool table and check out some books to
              better educate yourself. Use the links below to check out a book,
              view the current catalog, or recommend a new book (we’re looking
              to continue to expand to include more social issues like women's
              rights, LGBTQ+, ableism, etc)!
            </p>
          </div>
          <div class="ns-section">
            <div class="flex-vert">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSekerl0IdHAjO40KzbnaACRYnCyhuXBu78A8QNqsSNzoKiGSg/viewform"
                class="form-link w-inline-block"
              >
                <h3 class="h3">Check Out a Book</h3>
              </a>
              <a
                href="https://docs.google.com/spreadsheets/d/1kjwzcpDa7yc0IrxR9oPFr7B6EipZf2mMc0BHZE9P8J8/edit#gid=0"
                class="form-link w-inline-block"
              >
                <h3 class="h3">View Catalogue</h3>
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdrv7Nk26Z1lL3HYbYwKD0_4EdPj2OpL8Hc2C1yFxSPOb_6nQ/viewform"
                class="form-link w-inline-block"
              >
                <h3 class="h3">Recommend a Book</h3>
              </a>
              <div class="library-pic">
                <img
                  src="../images/diversity-inclusion/socialJusticeLibrary.jpeg"
                  alt="socialjusticelibrary"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diversity;
