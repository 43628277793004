import React, { useEffect } from "react";

const RepresentativeCard = ({ position, names }) => (
  <li id="repCardHolder">
    <div className="repCard">
      <p className="position">{position}</p>
      <h4 className="h4" style={{ textAlign: 'center' }}>{names}</h4>
    </div>
  </li>
);

function Representatives() {
  useEffect(() => {
    document.title = "Representatives";
  });
  return (
    <div className="content">
      <div className="w-container">
        <div className="page-head">
          <div className="page-title">
            <h1 className="h1">Representatives</h1>
          </div>
          <div className="flex-vert" style={{ marginTop: "-10px", marginBottom: "-40px" }}>
            <div className="sub-hrule"></div>
            <p className="paragraph-center sub">
              The Wiess Reps Program gives the people of Wiess the opportunity
              to use their individual passions and talents to serve their fellow
              Wiesspeople and make the Wiess experience even sweeter than it
              already is.
            </p>
          </div>
        </div>
        <div className="people-section">
          <div id="repsnav" className="from-navbar-ref"></div>
          <div id="reps" className="page-nav-ref"></div>
          <div class="sub-head" style={{ position: "relative", width: "100%", paddingBottom: "56.25%", height: "0", overflow: "hidden", marginBottom: "80px" }}>
            <iframe
              style={{ position: 'absolute', top: "0", left: "0", width: '100%', height: '100%' }}
              src="https://docs.google.com/presentation/d/1HQXktla6JwqrzXtzhcY1XEQE0zQ8OmHyZyJysr8Ogag/embed?start=false&loop=false&delayms=3000"
              frameborder="0"
              allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"
              title="RepSlides">
            </iframe>
          </div>
          <ul className="people-grid reps w-list-unstyled">
            <RepresentativeCard
              position="Basement Reps"
              names="George Bickham & Afolabi Durodola"
            />
            <RepresentativeCard
              position="Birthday Reps"
              names="Naija Bruckner, Emily Huynh, & Dori Olson"
            />
            <RepresentativeCard
              position="Boba Reps"
              names="Abdullah Jahangir, Samantha Lim, & Ashley Song"
            />
            <RepresentativeCard
              position="Chalkboard Reps"
              names="Aden Pugsley & Faith Zhang"
            />
            <RepresentativeCard
              position="College Night Reps"
              names="Virginia Minard, Connor Nguyen, & Athena Luo"
            />
            <RepresentativeCard 
              position="Computer Room Rep"
              names="Rocio Vides & Julian Villamar-Robins" 
            />
            <RepresentativeCard
              position="Decorations Reps"
              names="Sofie Rose Payne"
            />
            <RepresentativeCard
              position="Eco Reps"
              names="Milton Nguyen & Rosa Sabel"
            />
            <RepresentativeCard
              position="Election Reps"
              names="Avery McLain & Eli Risinger"
            />
            <RepresentativeCard
              position="Fire Pit Rep"
              names="Caden Acosta & Savan Patel"
            />
            <RepresentativeCard
              position="Floor Reps"
              names="1st Floor: Srilalitha Jyosyula, 2nd Floor: Emily Huynh, 3rd Floor: Peter Stern, 4th Floor: Connor Nguyen"
            />
            <RepresentativeCard 
              position="Food Rep"
              names="Rosa Sabel" 
            />
            <RepresentativeCard
              position="Fourth Floor Balcony Reps"
              names="Zoë Coleman & Faaeanuu Pepe "
            />
            <RepresentativeCard 
              position="Hair Reps"
              names="Jenny Karsner & Aria Johnson"
            />
            <RepresentativeCard
              position="International Student Ambassadors"
              names="Edwin Low & Isabella Villarroel"
            />
            <RepresentativeCard
              position="Intramural Sports Reps"
              names="Ben Bridges, Aislinn Burns, & Jenny Karsner"
            />
            <RepresentativeCard
              position="Kitchen Reps"
              names="Jack Pinkert"
            />
            <RepresentativeCard 
              position="Laundry Room Reps" 
              names="Aoife Shannon"
            />
            <RepresentativeCard 
              position="Librarian" 
              names="Alina Zhong"
            />
            <RepresentativeCard 
              position="Merch Reps" 
              names="Tobi Babalola & Vivian Lin" 
            />
            <RepresentativeCard 
              position="Movie Room Reps" 
              names="Jasper Coen, Halbert Kim, & Nedim Ozden" 
            />
            <RepresentativeCard
              position="Mop & Vacuum Rep"
              names="Ben Bridges & Peter Stern"
            />
            <RepresentativeCard 
              position="Music Reps" 
              names="Xavi Gallart, Owen Marye, & Eva Poduska" 
            />
            <RepresentativeCard 
              position="PODS Reps" 
              names="AJ Idowu & Jack Pinkert" 
            />
            <RepresentativeCard
              position="Positive Affirmation Reps"
              names="Rocio Gras, Sara Kalra, & Athena Luo"
            />
            <RepresentativeCard
              position="Rice Program Council Rep"
              names="Justine Candelaria"
            />
            <RepresentativeCard 
              position="Shower Rep" 
              names="Diego Garcia" 
            />
            <RepresentativeCard 
              position="Tailor Reps" 
              names="N/A" 
            />
            <RepresentativeCard
              position="Webmaster and Webmaster Apprentice"
              names="Maleah Brady & Faith Zhang"
            />
            <RepresentativeCard
              position="Wiess Cream Reps"
              names="Jack Li & Marco Tan"
            />
            <RepresentativeCard
              position="UpCo Rep"
              names="Ben Williams"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Representatives;
