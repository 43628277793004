import { useEffect } from "react";

function Court() {
  useEffect(() => {
    document.title = "Court";
  });
  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">Court</h1>
          </div>
          <div class="flex-vert" style={{ marginTop: "-10px" }}>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Wiess Court is an elected student body responsible for
              communicating and upholding the college’s expectations for
              appropriate student behavior. Wiess Court is not a punitive force,
              but instead acts to promote an environment of mutual respect.
            </p>
          </div>
          <div style={{ marginTop: "10px" }}>
            <a
              href="https://forms.gle/1mrj3tJqjgngcxDZ9"
              class="popup-button blm-button"
            >
              Report a College Infraction
            </a>
          </div>
        </div>
        <div class="people-section">
          <div id="courtnav" class="from-navbar-ref"></div>
          <div id="court" class="page-nav-ref"></div>
          <div class="court-grid">
            <div id="court-CJ-card-holder">
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTRyanLuCJ.jpg"
                    height="200"
                    alt="Ryan Lu"
                  />
                </div>
                <div>
                  <p class="position">Chief Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Ryan Lu</h4>
                </div>
                <div>
                  <a class="position" href="mailto: wiesscourt@gmail.com">
                    <u>wiesscourt@gmail.com</u>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTChloeSongAJ.jpeg"
                    height="200"
                    alt="Chloe Song"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Chloe Song</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTSaraKalraAJ.jpg"
                    height="200"
                    alt="Sara Kalra"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Sara Kalra</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTAoifeShannonAJ.jpg"
                    height="200"
                    alt="Aoifee Shannon"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Aoifee Shannon</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTChristinaBoufarahAJ.jpg"
                    height="200"
                    alt="Christina Boufarah"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Christina Boufarah</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTBenBridgesAJ.jpg"
                    height="200"
                    alt="Ben Bridges"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Ben Bridges</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="person-card">
                <div class="prof-pic">
                  <img
                    src="/images/court/COURTSkylerZinkerAJ.jpg"
                    height="200"
                    alt="Skyler Zinker"
                  />
                </div>
                <div>
                  <p class="position">Associate Justice</p>
                </div>
                <div class="name">
                  <h4 class="h4">Skyler Zinker</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Court;
