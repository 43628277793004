import "./App.css";
// Import necessary modules
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Import Navigation component
import Landing from "./Components/Landing-Home/Landing";
import Cabinet from "./Components/Government/Cabinet";
import CoreTeam from "./Components/Government/CoreTeam";
import Court from "./Components/Government/Court";
import Representatives from "./Components/Government/Representatives";
import Academics from "./Components/Resources/Academics";
import Associates from "./Components/Resources/Associates";
import Health from "./Components/Resources/Health";
import Diversity from "./Components/Resources/Diversity";
import NewStudents from "./Components/NewStudents/NewStudents";
import Forms from "./Components/Forms";
import Footer from "./Components/Footer";
import Navig from "./Components/Nav/Navig";

function App() {
  return (
    <Router>
      <div>
        {/* Navigation component */}
        <Navig />

        {/* Route Switch */}
        <Routes>
          {/* Define routes and corresponding components */}
          <Route path="/" element={<Landing />} />
          <Route path="/government/cabinet" element={<Cabinet />} />
          <Route path="/government/coreteam" element={<CoreTeam />} />
          <Route path="/government/court" element={<Court />} />
          <Route
            path="/government/representatives"
            element={<Representatives />}
          />
          <Route path="/resources/academics" element={<Academics />} />
          <Route path="/resources/associates" element={<Associates />} />
          <Route path="/resources/health" element={<Health />} />
          <Route path="/resources/diversity" element={<Diversity />} />
          <Route path="/newstudents" element={<NewStudents />} />
          <Route path="/forms" element={<Forms />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
