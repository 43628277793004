import { useEffect } from "react";

function Cabinet() {
  useEffect(() => {
    document.title = "Cabinet";
  });
  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">Cabinet</h1>
          </div>
          <div className="flex-vert" style={{ marginTop: "-10px" }}>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Cabinet is Wiess’ governing body, which holds open meetings every
              Wednesday in the Upper Commons or Large Classroom. All Wiessmen
              are encouraged to partake in the precarious balance of bedlam and
              decision-making that ensues at Cabinet.
            </p>
          </div>
        </div>
        <div class="people-section">
          <div id="cabinetnav" class="from-navbar-ref"></div>
          <div id="cabinet" class="page-nav-ref"></div>
          { /*
          <div class="sub-head" style={{ position: "relative", width: "100%", paddingBottom: "56.25%", height: "0", overflow: "hidden", marginBottom: "60px" }}>
            <iframe
              style={{ position: 'absolute', top: "0", left: "0", width: '100%', height: '100%' }}
              src="https://www.youtube.com/embed/qfL_Jvc5V_8"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="cabVid"
            ></iframe>
          </div>
          */ }
          <div class="people-grid">
            <div class="person-card" id="court-CJ-card-holder">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABChristinaChenPres.jpg"
                  height="200"
                  alt="Christina Chen"
                ></img>
              </div>
              <div>
                <p class="position">President</p>
              </div>
              <div class="name">
                <h4 class="h4">Christina Chen</h4>
              </div>
              <div>
                <a
                  class="position"
                  href="mailto: wiesscollegepresident@gmail.com"
                >
                  <u>wiesscollegepresident@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/court/COURTRyanLuCJ.jpg"
                  height="200"
                  alt="Ryan Lu"
                />
              </div>
              <div>
                <p class="position">Chief Justice</p>
              </div>
              <div class="name">
                <h4 class="h4">Ryan Lu</h4>
              </div>
              <div>
                <a class="position" href="mailto: wiesscourt@gmail.com">
                  <u>wiesscourt@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABGrantWilsonIvp.jpg"
                  height="200"
                  alt="Grant Wilson"
                />
              </div>
              <div>
                <p class="position">Internal Vice President</p>
              </div>
              <div class="name">
                <h4 class="h4">Grant Wilson</h4>
              </div>
              <div>
                <a class="position" href="mailto: wiesscollegeivp@gmail.com">
                  <u>wiesscollegeivp@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABMiltonNguyenEVP.jpeg"
                  height="200"
                  alt="Milton Nguyen"
                />
              </div>
              <div>
                <p class="position">External Vice President</p>
              </div>
              <div class="name">
                <h4 class="h4">Milton Nguyen</h4>
              </div>
              <div>
                <a class="position" href="mailto: wiesscollegeevp@gmail.com">
                  <u>wiesscollegeevp@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABRocioGrasSocialVicePresident.jpg"
                  height="200"
                  alt="Rocio Gras"
                />
              </div>
              <div>
                <p class="position">Social Vice President</p>
              </div>
              <div class="name">
                <h4 class="h4">Rocio Gras</h4>
              </div>
              <div>
                <a class="position" href="mailto: wiesssocialvp@gmail.com">
                  <u>wiesssocialvp@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABIsabelleShenSocialVicePresident.JPG"
                  height="200"
                  alt="Isabelle Shen"
                />
              </div>
              <div>
                <p class="position">Social Vice President</p>
              </div>
              <div class="name">
                <h4 class="h4">Isabelle Shen</h4>
              </div>
              <div>
                <a class="position" href="mailto: wiesssocialvp@gmail.com">
                  <u>wiesssocialvp@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABLuizFernandesSenator.jpeg"
                  height="200"
                  alt="Luiz Fernandez"
                />
              </div>
              <div>
                <p class="position">SA Senator</p>
              </div>
              <div class="name">
                <h4 class="h4">Luiz Fernandez</h4>
              </div>
              <div>
                <a
                  class="position" href="mailto: wiesscollegesenator@gmail.com"
                >
                  <u>wiesscollegesenator@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABAniGuptaHeadTreasurer.jpg"
                  height="200"
                  alt="Ani Gupta"
                />
              </div>
              <div>
                <p class="position">Head Treasurer</p>
              </div>
              <div class="name">
                <h4 class="h4">Ani Gupta</h4>
              </div>
              <div>
                <a class="position" href="mailto: anirudh.s.gupta@rice.edu">
                  <u>anirudh.s.gupta@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABSergioKaramApprenticeTreasurer.jpg"
                  height="200"
                  alt="Sergio Karam"
                />
              </div>
              <div>
                <p class="position">Apprentice Treasurer</p>
              </div>
              <div class="name">
                <h4 class="h4">Sergio Karam</h4>
              </div>
              <div>
                <a class="position" href="mailto: sbk8@rice.edu">
                  <u>sbk8@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABBarakatIbrahimSeniorRep.JPG"
                  height="200"
                  alt="Barakat Ibrahim"
                />
              </div>
              <div>
                <p class="position">Senior Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Barakat Ibrahim</h4>
              </div>
              <div>
                <a class="position" href="mailto: bai1@rice.edu">
                  <u>bai1@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABPeterSternJuniorRep.JPG"
                  height="200"
                  alt="Peter Stern"
                />
              </div>
              <div>
                <p class="position">Junior Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Peter Stern</h4>
              </div>
              <div>
                <a class="position" href="mailto: pfs3@rice.edu">
                  <u>pfs3@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABDiegoRicoSophomoreRep.JPG"
                  height="200"
                  alt="Diego Rico"
                />
              </div>
              <div>
                <p class="position">Sophomore Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Diego Rico</h4>
              </div>
              <div>
                <a class="position" href="mailto: dr56@rice.edu">
                  <u>dr56@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABVivianLinSophomoreRep.jpg"
                  height="200"
                  alt="Vivian Lin"
                />
              </div>
              <div>
                <p class="position">Sophomore Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Vivian Lin</h4>
              </div>
              <div>
                <a class="position" href="mailto: vl23@rice.edu">
                  <u>vl23@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABAlinaZhongDiversityChair.jpg"
                  height="200"
                  alt="Alina Zhong"
                />
              </div>
              <div>
                <p class="position">Diversity Chair</p>
              </div>
              <div class="name">
                <h4 class="h4">Alina Zhong</h4>
              </div>
              <div>
                <a class="position" href="mailto: az37@rice.edu">
                  <u>az37@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABLayalHaiderDiversityChair.jpeg"
                  height="200"
                  alt="Layal Haider"
                />
              </div>
              <div>
                <p class="position">Diversity Chair</p>
              </div>
              <div class="name">
                <h4 class="h4">Layal Haider</h4>
              </div>
              <div>
                <a class="position" href="mailto: lh55@rice.edu">
                  <u>lh55@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABCaitlynLaddSecretary.jpg"
                  height="200"
                  alt="Caitlyn Ladd"
                />
              </div>
              <div>
                <p class="position">Secretary</p>
              </div>
              <div class="name">
                <h4 class="h4">Caitlyn Ladd</h4>
              </div>
              <div>
                <a
                  class="position"
                  href="mailto: wiesscollegesecretary@gmail.com"
                >
                  <u>wiesscollegesecretary@gmail.com</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABConnorNguyenParliamentarian.jpg"
                  height="200"
                  alt="Connor Nguyen"
                />
              </div>
              <div>
                <p class="position">Parliamentarian</p>
              </div>
              <div class="name">
                <h4 class="h4">Connor Nguyen</h4>
              </div>
              <div>
                <a class="position" href="mailto: chn6@rice.edu">
                  <u>chn6@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABParkerJiaHistorian.jpg"
                  height="200"
                  alt="Parker Jia"
                />
              </div>
              <div>
                <p class="position">Historian</p>
              </div>
              <div class="name">
                <h4 class="h4">Parker Jia</h4>
              </div>
              <div>
                <a class="position" href="mailto: pj16@rice.edu">
                  <u>pj16@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABAislinnBurnsHistorian.jpg"
                  height="200"
                  alt="Aislinn Burns"
                />
              </div>
              <div>
                <p class="position">Historian</p>
              </div>
              <div class="name">
                <h4 class="h4">Aislinn Burns</h4>
              </div>
              <div>
                <a class="position" href="mailto: akb15@rice.edu">
                  <u>akb15@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABMichaelWangAcademics&CareerChair.JPG"
                  height="200"
                  alt="Michael Wang"
                />
              </div>
              <div>
                <p class="position">Academics and Career Chair</p>
              </div>
              <div class="name">
                <h4 class="h4">Michael Wang</h4>
              </div>
              <div>
                <a class="position" href="mailto: mw87@rice.edu">
                  <u>mw87@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABLucyBozinovAcademics&CareerChair.jpg"
                  height="200"
                  alt="Lucy Bozinov"
                />
              </div>
              <div>
                <p class="position">Academics and Career Chair</p>
              </div>
              <div class="name">
                <h4 class="h4">Lucy Bozinov</h4>
              </div>
              <div>
                <a class="position" href="mailto: leb13@rice.edu">
                  <u>leb13@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABBenBridgesOCRep&CapitalImprovementsRep.jpg"
                  height="200"
                  alt="Ben Bridges"
                />
              </div>
              <div>
                <p class="position">Capital Improvements Representative and Off-Campus Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Ben Bridges</h4>
              </div>
              <div>
                <a class="position" href="mailto: bb73@rice.edu">
                  <u>bb73@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/cabinet/CABChaseGeyerOCRep.jpg"
                  height="200"
                  alt="Chase Geyer"
                />
              </div>
              <div>
                <p class="position">Off-Campus Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">Chase Geyer</h4>
              </div>
              <div>
                <a class="position" href="mailto: cjg8@rice.edu">
                  <u>cjg8@rice.edu</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/general/wiessshield.svg"
                  height="200"
                  alt="First Year Representative"
                />
              </div>
              <div>
                <p class="position">First Year Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">N/A</h4>
              </div>
              <div>
                <a class="position" href="mailto: ">
                  <u>N/A</u>
                </a>
              </div>
            </div>
            <div class="person-card">
              <div class="prof-pic">
                <img
                  src="/images/general/wiessshield.svg"
                  height="200"
                  alt="SA New Student Representative"
                />
              </div>
              <div>
                <p class="position">SA New Student Representative</p>
              </div>
              <div class="name">
                <h4 class="h4">N/A</h4>
              </div>
              <div>
                <a class="position" href="mailto: ">
                  <u>N/A</u>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabinet;
